import ReactSelect from "react-select";
import CurrencyDefaults from "../currency-defaults";
import Calculator from '../calculator';
import { Link } from "react-router-dom";
import {useState, useEffect, useRef} from 'react';
import TooltipLabel from "./tooltip-label";
import Config from "../config";

function MoveExpenseModal(props) {

  const amountInputRef = useRef(null);
	const [campaignOptions, setCampaignOptions] = useState([]);
	const [selectedCampaign, setSelectedCampaign] = useState(props.model.receivingCampaign);
	const [displayAmount, setDisplayAmount] = useState('');
	
	useEffect(() => {
		if (props.model.visible) {
			setSelectedCampaign(props.model.receivingCampaign);
		}
	}, [props.model.receivingCampaign]);

	useEffect(() => {
		if (props.model.visible && props.model.companyData) {
			updateCampaignOptions();
		}
	}, [props.model.companyData]);
	
	useEffect(() => {
    if (props.model.visible) {
      updateAmountValues(props.model.internalExpenseAmount)
    }
  }, [props.model.internalExpenseAmount]);
	
	const updateCampaignOptions = () => {
		const campaigns = props.model.companyData.map(x => x.campaign); // Can't validate funding type. Funding type can vary based on month transferring into.
		const uniqueCampaigns = [...new Map(campaigns.map(campaign => [campaign.id, campaign])).values()];
		const options = uniqueCampaigns.map(x => ({ label: x.name, value: x.id }));
		setCampaignOptions(options);
	};

	const handlePlatformChange = (option) => {
		props.model.receivingPlatform = props.model.allowedPlatformOptions
			.find(x => x.value === option.value);
	};
	
	const handleCampaignChange = (option) => {
		setSelectedCampaign(option);
		props.model.receivingCampaign = option;
	};
	
	const handleAmountChange = (e) => {
    setDisplayAmount(e.target.value);
  };
  
	const handleAmountBlur = (e) => {
    updateAmountValues(e.target.value);
  };
  
	const updateAmountValues = (value) => {
    const rawValue = value.toString().replace(/[^0-9.]/g, '');
    setDisplayAmount(CurrencyDefaults.formatMicrodollar(rawValue)); // Handle complex use cases around when to introduce microdollar precision when acting on calculated values
    let grossAmount = Calculator.calculateIOAmount(
			rawValue,
			Calculator.getMargin(props.model.campaignGroupMonth.ioLineItems)
		);
    props.updateModel({
      ...props.model,
      internalExpenseAmount: rawValue,
      ioAmount: CurrencyDefaults.round(grossAmount, 2)
    });
  };
  
	return <div className={'transfer-modal'}>
		{props.model.visible &&
			<div>
				<div className="modal modal-scrollable fade show" id="logoutModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
					style={{display: 'block'}} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">
									Media Expense Transfer
								</h5>
								<div style={{cursor: 'default'}}>
									<button
										onClick={props.cancel}
										className="close"
										type="button"
										data-dismiss="modal"
										aria-label="Close">
										<span aria-hidden="true">×</span>
									</button>
								</div>
							</div>
							<div className="modal-body">
								{props.model.errorMessage && 
									<div className="alert alert-danger" role="alert">
										{props.model.errorMessage}
									</div>
								}
								<div className="row">
									<div className="col-md-6">
										<div className="transfer-from p-3 border rounded">
											<h6 className="border-bottom pb-2 mb-2">From</h6>
											<div className="mb-2">
												<strong className="d-block mb-1">Company</strong>
												<div>{props.model.company.name}</div>
											</div>
											<div className="mb-2">
												<strong className="d-block mb-1">Platform</strong>
												<div>{Config.getPlatformText(props.model.platform)}</div>
											</div>
											<div className="mb-2">
												<strong className="d-block mb-1">Campaign</strong>
												<div>{props.model.campaign.name}</div>
											</div>
											<div className="mb-2">
												<strong className="d-block mb-1">Date</strong>
												<div className="row g-0">
													<div className="col">
														{new Date(props.model.year, props.model.month - 1)
														.toLocaleString('default', {month: 'long', year: 'numeric'})}
													</div>
												</div>
											</div>
											<div className="mb-2">
												<strong className="d-block mb-1">Campaign Budget</strong>
												<div className="row">
													<div className="col-md-4">
														<div>
															<small className="text-muted d-block">Gross</small>
															<div>{CurrencyDefaults.format(props.model.campaignGroupMonth.ioAmount)}</div>
														</div>
													</div>
													<div className="col-md-4">
														<div>
															<small className="text-muted d-block">Margin</small>
															<div>{CurrencyDefaults.formatPercentage(Calculator.getMargin(props.model.campaignGroupMonth.ioLineItems))}</div>
														</div>
													</div>
													<div className="col-md-4">
														<div>
															<small className="text-muted d-block">Funding</small>
															<div>{Config.getFundingDescription(props.model.campaignGroupMonth.funding)}</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
														<div>
															<small className="text-muted d-block">Planned</small>
															<div>{CurrencyDefaults.format(props.model.campaignGroupMonth.plannedInvoiceAmount)}</div>
														</div>
													</div>
													<div className="col-md-4">
														<div>
															<small className="text-muted d-block">Actual</small>
															<div>
																{props.model.campaignGroupMonth.actualExpense
																	? CurrencyDefaults.format(props.model.campaignGroupMonth.actualExpense.invoiceAmount)
																	: '$0.00'}
															</div>
														</div>
													</div>
													<div className="col-md-4">
														<div>
															<small className="text-muted d-block">Available</small>
															<div>
																{
																	CurrencyDefaults.format(
																	CurrencyDefaults
																		.factory(props.model.campaignGroupMonth.plannedInvoiceAmount)
																		.subtract((props.model.campaignGroupMonth.actualExpense?.invoiceAmount || 0))
																	)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="transfer-to p-3 border rounded">
											<h6 className="border-bottom pb-2 mb-2">To</h6>
											<TooltipLabel
												label="Platform"
												tooltipId="platform-tooltip">
												<div className="p-2">
													When funding is RW, platform options are limited to those sharing the same payment type.<br/>
													<Link to="/payment-type-assignments" target="_blank" className="text-info ms-1">
														Payment Type Assignments
													</Link>
												</div>
											</TooltipLabel>
											<div className="mb-2">
												<ReactSelect
													className="react-select"
													options={props.model.allowedPlatformOptions || []}
													isSearchable={true}
													defaultValue={props.model.receivingPlatform || null}
													onChange={handlePlatformChange}
												/>
											</div>
											<div className="mb-2">
												<strong className="d-block mb-1">Campaign</strong>
												<ReactSelect
													className="react-select"
													options={campaignOptions}
													isSearchable={true}
													value={selectedCampaign || null}
													onChange={handleCampaignChange}
												/>
											</div>
											<div className="mb-2">
												<TooltipLabel 
													label="Funding"
													tooltipId="funding-tooltip"
												>
													<div className="p-2">
														Transfers must be within the same funding type (system validates on save).
													</div>
												</TooltipLabel>
												{Config.getFundingDescription(props.model.campaignGroupMonth.funding)}
											</div>
											<div className="row mb-2">
												<div className="col-md-6">
													<strong className="d-block mb-1">Month</strong>
													<ReactSelect
														className="react-select"
														options={props.model.monthOptions}
														isSearchable={true}
														defaultValue={props.model.receivingMonth}
														onChange={(option) => {
															props.model.receivingMonth = props.model.monthOptions
															.find(x => x.value === option.value);
														}}
													/>
												</div>
												<div className="col-md-6">
													<strong className="d-block mb-1">Year</strong>
													<ReactSelect
														className="react-select"
														options={props.model.yearOptions}
														isSearchable={true}
														defaultValue={props.model.receivingYear}
														onChange={(option) => {
															props.model.receivingYear = props.model.yearOptions
															.find(x => x.value === option.value);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="transfer-amount p-3 mt-4 border rounded">
									<h6 className="border-bottom pb-2 mb-3">Amount</h6>
									<div className="row">
										<div className="col-md-6 mb-3">
											<strong className="d-block mb-1">Planned Amount to Transfer</strong>
											<input type="text"
												className="form-control"
												ref={amountInputRef}
												value={displayAmount}
												onBlur={handleAmountBlur} 
												onChange={handleAmountChange}
											/>
										</div>
										<div className="col-md-6 mb-3">
											<TooltipLabel 
												label="Gross Amount to Transfer"
												tooltipId="gross-amount-to-transfer-tooltip"
											>
												<div className="p-2">
													Gross amount is calculated using markup conversion: margin ÷ (1 - margin)
												</div>
											</TooltipLabel>
											<input type="text"
												className="form-control bg-light"
												value={CurrencyDefaults.formatFixed(props.model.ioAmount)}
												readOnly
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer justify-content-between">
								<button onClick={props.cancel}
												className="btn btn-outline-orange"
												data-dismiss="modal"
												disabled={props.model.saving}>
									Cancel
								</button>
								<button className="btn btn-orange"
												onClick={props.save}
												disabled={props.model.saving}>
									Confirm Transfer
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop fade show"></div>
			</div>
		}
	</div>
}

export default MoveExpenseModal;