import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CurrencyDefaults from '../currency-defaults';
import ReactSelect from 'react-select';
import ApiClient from '../api-client';
import Template from './template';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import '../css/reconciliation.css';
import Config from '../config';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function ReconciliationReport() {
	const getDefaultDates = () => {
		const today = new Date();
		const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1);
		const yearMonth = `${lastMonth.getFullYear()}-${String(lastMonth.getMonth() + 1).padStart(2, '0')}`;
		return {
			startYearMonth: yearMonth,
			endYearMonth: yearMonth
		};
	};
	
	const defaultDates = getDefaultDates();
	const [companySelectValue, setCompanySelectValue] = useState('');
	const [companySelectData, setCompanySelectData] = useState([]);
	const [startYearMonth, setStartYearMonth] = useState(defaultDates.startYearMonth);
	const [endYearMonth, setEndYearMonth] = useState(defaultDates.endYearMonth);
	const [reconciliationData, setReconciliationData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tableLoading, setTableLoading] = useState(true);
	const [selectedFundings, setSelectedFundings] = useState([]);

	const columns = [
		{
			name: 'Company',
			selector: row => row.company.name,
			sortable: true
		},
		{
			name: 'Funding',
			selector: row => row.funding,
			cell: row => <div>{Config.getFundingDescription(row.funding)}</div>,
			sortable: true,
			width: '100px'
		},
		{
			name: 'IO Amount',
			selector: row => row.ioAmount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.ioAmount)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'Planned Margin Amount',
			selector: row => row.plannedMarginAmount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.plannedMarginAmount)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'Planned Platform Spend',
			selector: row => row.plannedPlatformSpend,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.plannedPlatformSpend)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'Authoritative Actual',
			selector: row => row.authoritativeActualAmount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.authoritativeActualAmount)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'Planned vs Authoritative Difference',
			selector: row => row.plannedVsAuthoritativeDifference,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.plannedVsAuthoritativeDifference)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'Estimated Actual',
			selector: row => row.estimatedActualAmount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.estimatedActualAmount)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'MX Gross',
			selector: row => row.mxGross,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.mxGross)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'Gross Discrepancy',
			selector: row => row.grossDiscrepancy,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.grossDiscrepancy)}</div>,
			width: '150px',
			sortable: true
		},
		{
			name: 'Gross Transfer Amount',
			selector: row => row.grossTransferAmount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.grossTransferAmount)}</div>,
			width: '150px',
			sortable: true
		}
	];

	const fundingOptions = [
		{ value: null, label: Config.getFundingDescription(null) },  // "N/A"
		{ value: 0, label: Config.getFundingDescription(0) },        // "RW"
		{ value: 1, label: Config.getFundingDescription(1) }         // "Client"
	];

	useEffect(() => {
		setDataAndApplyFilters(reconciliationData);
	}, [companySelectValue, selectedFundings]);

	useEffect(() => {
		async function fetchData() {
			try {
				setTableLoading(true);
				const data = await new ApiClient().fetch(
					`/Campaign/reconciliation?startYearMonth=${startYearMonth}&endYearMonth=${endYearMonth}`,
					'GET'
				);
				setReconciliationData(data);
				setTableLoading(false);
				setDataAndApplyFilters(data);
			} catch (error) {
				// Toast handles errors
			}
		}
		fetchData();
	}, [startYearMonth, endYearMonth]);

	function setDataAndApplyFilters(data) {
		let filteredData = data.filter(record => {
			const companyMatch = !companySelectValue || record.company.id === companySelectValue;
			const fundingMatch = selectedFundings.length === 0 || 
				selectedFundings.some(sf => sf.value === record.funding);
			return companyMatch && fundingMatch;
		});

		// First group by company and funding
		const groupedData = filteredData.reduce((acc, row) => {
			const key = `${row.company.id}-${row.funding}`;
			if (!acc[key]) {
				acc[key] = {
					id: key,
					company: row.company,
					funding: row.funding,
					ioAmount: CurrencyDefaults.factory(0),
					plannedMarginAmount: CurrencyDefaults.factory(0),
					plannedPlatformSpend: CurrencyDefaults.factory(0),
					authoritativeActualAmount: CurrencyDefaults.factory(0),
					estimatedActualAmount: CurrencyDefaults.factory(0),
					mxGross: CurrencyDefaults.factory(0),
					grossDiscrepancy: CurrencyDefaults.factory(0),
					grossTransferAmount: CurrencyDefaults.factory(0),
					platforms: {}
				};
			}
			
			// Add platform details
			const platformKey = row.platform.id;
			if (!acc[key].platforms[platformKey]) {
				acc[key].platforms[platformKey] = {
					platform: row.platform,
					ioAmount: CurrencyDefaults.factory(0),
					plannedMarginAmount: CurrencyDefaults.factory(0),
					plannedPlatformSpend: CurrencyDefaults.factory(0),
					authoritativeActualAmount: CurrencyDefaults.factory(0),
					estimatedActualAmount: CurrencyDefaults.factory(0),
					mxGross: CurrencyDefaults.factory(0),
					grossDiscrepancy: CurrencyDefaults.factory(0),
					grossTransferAmount: CurrencyDefaults.factory(0)
				};
			}

			// Sum up totals for both company and platform levels
			[acc[key], acc[key].platforms[platformKey]].forEach(target => {
				target.ioAmount = target.ioAmount.add(row.ioAmount);
				target.plannedMarginAmount = target.plannedMarginAmount.add(row.plannedMarginAmount);
				target.plannedPlatformSpend = target.plannedPlatformSpend.add(row.plannedPlatformSpend);
				target.authoritativeActualAmount = target.authoritativeActualAmount.add(row.authoritativeActualAmount);
				target.plannedVsAuthoritativeDifference = target.plannedPlatformSpend.subtract(target.authoritativeActualAmount);
				target.estimatedActualAmount = target.estimatedActualAmount.add(row.estimatedActualAmount);
				target.mxGross = target.mxGross.add(row.mxGross);
				target.grossDiscrepancy = target.grossDiscrepancy.add(row.grossDiscrepancy);
				target.grossTransferAmount = target.grossTransferAmount.add(row.grossTransferAmount);
			});

			return acc;
		}, {});

		const aggregatedData = Object.values(groupedData);
		setFilters(filteredData);
		setTableData(aggregatedData);
	}

	function setFilters(data) {
		const companiesMap = new Map();
		data.forEach(x => {
			if (!companiesMap.has(x.company.id)) {
				companiesMap.set(x.company.id, x.company.name);
			}
		});
		
		const companies = Array.from(companiesMap, ([id, name]) => ({ label: name, value: id }));
		companies.sort((a, b) => a.label.localeCompare(b.label));
		
		setCompanySelectData(companies);
	}

	const expandableRowsComponent = ({ data }) => {
		const platforms = Object.values(data.platforms);
		return (
			<div className="p-3">
				<table className="table table-sm table-striped">
					<thead>
						<tr>
							<th className="text-dark">Platform</th>
							<th className="text-dark text-right">IO Amount</th>
							<th className="text-dark text-right">Planned Margin Amount</th>
							<th className="text-dark text-right">Planned Platform Spend</th>
							<th className="text-dark text-right">Authoritative Actual</th>
							<th className="text-dark text-right">Planned vs Authoritative Difference</th>
							<th className="text-dark text-right">Estimated Actual</th>
							<th className="text-dark text-right">MX Gross</th>
							<th className="text-dark text-right">Gross Discrepancy</th>
							<th className="text-dark text-right">Gross Transfer Amount</th>
						</tr>
					</thead>
					<tbody>
						{platforms.map((platform, index) => (
							<tr key={index}>
								<td className="text-dark">{platform.platform.name}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.ioAmount)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.plannedMarginAmount)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.plannedPlatformSpend)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.authoritativeActualAmount)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.plannedVsAuthoritativeDifference)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.estimatedActualAmount)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.mxGross)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.grossDiscrepancy)}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.grossTransferAmount)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	return <Template selectedNavItem="media-expenses">
		<div className="reconciliation-screen">
			<div className="row">
				<div className="col-xl-3 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Start Month</label>
						<input
							type="month"
							className="form-control date-control"
							value={startYearMonth}
							onChange={e => setStartYearMonth(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-xl-3 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">End Month</label>
						<input
							type="month"
							className="form-control date-control"
							value={endYearMonth}
							onChange={e => setEndYearMonth(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-xl-3 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Company</label>
						<ReactSelect
							className="react-select"
							options={companySelectData}
							isSearchable={true}
							isClearable={true}
							placeholder="Company"
							onChange={(option) => setCompanySelectValue(option ? option.value : '')}
						/>
					</div>
				</div>
				<div className="col-xl-3 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Funding</label>
						<ReactSelect
							className="react-select"
							options={fundingOptions}
							isMulti={true}
							placeholder="Funding"
							value={selectedFundings}
							onChange={setSelectedFundings}
						/>
					</div>
				</div>
			</div>
			<hr/>
			<div className="row">
				<div className="col-xl-12">
					<div className="mb-3">
						<div className="card shadow mb-2">
							<div className="card-body py-2">


								<div className="d-flex align-items-start">
									<div className="mt-1 me-2">
										<span className="font-weight-bold">Grand Totals:</span>
									</div>
									<div className="flex-grow-1">
										<table className="table table-sm mb-0">
											<thead>
											<tr>
												<th className="text-dark text-center">IO Amount</th>
												<th className="text-dark text-center">Planned Margin Amount</th>
												<th className="text-dark text-center">Planned Platform Spend</th>
												<th className="text-dark text-center">Authoritative Actual</th>
												<th className="text-dark text-center">Planned vs Authoritative Difference</th>
												<th className="text-dark text-center">Estimated Actual</th>
												<th className="text-dark text-center">MX Gross</th>
												<th className="text-dark text-center">Gross Discrepancy</th>
												<th className="text-dark text-center">Gross Transfer Amount</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												{[
													'ioAmount',
													'plannedMarginAmount',
													'plannedPlatformSpend',
													'authoritativeActualAmount',
													'plannedVsAuthoritativeDifference',
													'estimatedActualAmount',
													'mxGross',
													'grossDiscrepancy',
													'grossTransferAmount'
												].map(field => (
													<td key={field} className="text-dark text-center">
														{CurrencyDefaults.formatFixed(
															tableData.reduce(
																(sum, row) => sum.add(row[field]),
																CurrencyDefaults.factory(0)
															)
														)}
													</td>
												))}
											</tr>
											</tbody>
										</table>
									</div>
								</div>


							</div>
						</div>
					</div>

					<DataTable
						columns={columns}
						data={tableData}
						striped={true}
						highlightOnHover={true}
						dense={true}
						pagination={true}
						paginationPerPage={100}
						paginationRowsPerPageOptions={[100, 250, 500]}
						expandableRows
						expandableRowsComponent={expandableRowsComponent}
						expandOnRowClicked
						paginationComponentOptions={{
							selectAllRowsItem: true
						}}
						progressPending={tableLoading}
						responsive={true}
						customStyles={{
							cells: {
								style: {
									padding: '5px'
								},
							},
							headCells: {
								style: {
									paddingLeft: '5px',
									paddingRight: '5px',
									'& div': {
										overflow: 'visible',
										whiteSpace: 'normal',
										width: '100%',
										textAlign: 'center'
									},
								},
							}
						}}
					/>
				</div>
			</div>
		</div>
	</Template>;
}

export default ReconciliationReport;