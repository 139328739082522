import Config from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import CurrencyDefaults from "../currency-defaults";
import React from "react";

const IOLink = ({ ioId, columnSelector, ioIndex, delimeter }) => (
    <span key={`${columnSelector}-${ioId}-${ioIndex}`}>
    <a target="_blank" rel="noreferrer" className="io-link clickable" href={`${Config.getIoUrl()}/io/view/${ioId}/`}>
      IO: {ioId}
    </a>
        {delimeter}
        <FontAwesomeIcon icon={faExternalLinkAlt} className="fa-sm fa-fw ml-2" />
  </span>
);

const CampaignGroupCell = ({
   campaignGroupMonth,
   showIo,
   hasWrite,
   showIoAmount,
   showPlannedAmount,
   showMargin,
   showInvoiceAmount,
   showInvoiceNumber,
   columnSelector,
   setTransferModal,
   row,
   selectedYear,
   campaignQueryData,
   paymentTypeAssignments,
   showMonthModal,
   month,
   showFlightDate}) => {
    const handleClickForTransferModal = () => {
        if (!hasWrite) {
            return;
        }
        if (!campaignGroupMonth) {
            return;
        }
        let companyData = campaignQueryData.filter(x => x.company.id === row.company.id);
        let campaign = companyData.find(x => x.campaign.id === row.campaign.id).campaign;
        let defaultCampaignOption = { label: campaign.name, value: campaign.id }
        const monthOptions = Config.monthNames.map((name, index) => ({
            value: index + 1,
            label: name
        }));
        const currentMonthOption = monthOptions.find(option => option.value === month);
        const selectedYearParsed = parseInt(selectedYear);
        const yearOptions = [
            { value: selectedYearParsed, label: selectedYearParsed.toString() },
            { value: selectedYearParsed + 1, label: (selectedYearParsed + 1).toString() }
        ];
        const currentYearOption = yearOptions.find(option => option.value === selectedYear);
        const paymentTypeAssignment = paymentTypeAssignments.find(a =>
          a.company?.id === row.company.id && 
          a.platform?.id === row.platform.id
        )?.paymentType;
        let allowedPlatforms;
        if (campaignGroupMonth.funding == 0)
        {
          allowedPlatforms = paymentTypeAssignments
          .filter(a => 
              a.company?.id === row.company.id &&
              (paymentTypeAssignment 
                  ? a.paymentType === paymentTypeAssignment  // Only match if both have same explicit payment type (not null-to-null)
                  : a.platform?.id === row.platform.id)
          ).map(x => x.platform);
          if (!allowedPlatforms.some(x => x.id == row.platform.id)) {
            allowedPlatforms.push(row.platform);
          }
        }
        else
        {
          allowedPlatforms = [...new Map(companyData.map(company => [company.platform.id, company.platform])).values()];
        }
        allowedPlatforms.sort((a, b) => (Config.getPlatformText(a) > Config.getPlatformText(b)) ? 1 : -1);
        const allowedPlatformOptions = allowedPlatforms.map(a => ({ label: Config.getPlatformText(a), value: a.id }));
        const defaultPlatformOption =  allowedPlatformOptions.find(option => option.value === row.platform.id);
        let transferAmount = campaignGroupMonth
          ? CurrencyDefaults.roundMicroDollar(
              CurrencyDefaults
                .factory(campaignGroupMonth.plannedInvoiceAmount)
                .subtract(campaignGroupMonth.actualExpense?.invoiceAmount || 0)
            )
          : null;
        setTransferModal({
            visible: true,
            campaignGroupMonth,
            company: row.company,
            campaign: row.campaign,
            platform: row.platform,
            year: selectedYear,
            month,
            ioAmount: null,
            internalExpenseAmount: transferAmount,
            receivingCampaign: defaultCampaignOption,
            monthOptions: monthOptions,
            receivingMonth: currentMonthOption,
            saving: false,
            receivingYear: currentYearOption,
            yearOptions: yearOptions,
            allowedPlatformOptions: allowedPlatformOptions,
            receivingPlatform: defaultPlatformOption,
            companyData,
            campaignOptions: []
        });
    };

    const getFunding = () => {
        if (!campaignGroupMonth) {
            return 'N/A';
        }
        return Config.getFundingDescription(campaignGroupMonth.funding);
    };
    return (
        <div className="campaign-group-cell">
            { showFlightDate && campaignGroupMonth?.flightStartDate != null && campaignGroupMonth?.flightEndDate && <div>{campaignGroupMonth?.flightStartDate} - {campaignGroupMonth?.flightEndDate}</div>}
            { campaignGroupMonth && showIo && hasWrite && campaignGroupMonth.ioLineItems && campaignGroupMonth.ioLineItems.length > 0 &&
                <div className="io-links-container">
                    {
                        campaignGroupMonth.ioLineItems
                            .filter(x => (x.iosOrderIds || []).length > 0) // Exclude transfers
                            .map((x) => {
                                    return x.iosOrderIds.map((ioId, ioIndex, array) => {
                                        return <IOLink
                                            key={`${columnSelector}-${ioId}-${ioIndex}`}
                                            ioId={ioId}
                                            columnSelector={columnSelector}
                                            ioIndex={ioIndex}
                                            delimeter={ioIndex < array.length - 1 ? ', ' : ''}
                                        />;
                                        }
                                    );
                                }
                            )
                    }
                </div>
            }
            { showIoAmount && hasWrite &&
                <div>Gross: {campaignGroupMonth ? CurrencyDefaults.format(campaignGroupMonth.ioAmount) : CurrencyDefaults.format(0)}</div> }
            { <div>Funding: {getFunding()}</div> }
            { showPlannedAmount &&
                <div
                    className={campaignGroupMonth && hasWrite ? 'clickable' : ''}
                    onClick={handleClickForTransferModal}>
                    <div>Planned: {campaignGroupMonth ? CurrencyDefaults.format(campaignGroupMonth.plannedInvoiceAmount) : CurrencyDefaults.format(0)}</div>
                </div>
            }
            { showMargin && hasWrite &&
                <div className={hasWrite ? 'clickable' : ''}
                     onClick={() => showMonthModal(campaignGroupMonth, row.company, row.campaign, row.platform, month)}>
                    Margin:&nbsp;
                    {
                        !campaignGroupMonth || !campaignGroupMonth.ioLineItems || campaignGroupMonth.ioLineItems.length < 1
                            ? '0'
                            : Array.from(new Set(campaignGroupMonth.ioLineItems.map(x => x.margin))).map((margin, index, array) => {
                                return <span key={`${columnSelector}-${index}-margin`}>{margin}{ index < array.length - 1 ? ', ' : '' }</span>
                            })
                    }
                </div>
            }
            { showInvoiceAmount &&
                <div className={hasWrite ? 'clickable' : ''}
                     onClick={() => showMonthModal(campaignGroupMonth, row.company, row.campaign, row.platform, month)}>
                    Invoiced:&nbsp;
                    {campaignGroupMonth && campaignGroupMonth.actualExpense && campaignGroupMonth.actualExpense.invoiceAmount
                        ? CurrencyDefaults.format(campaignGroupMonth.actualExpense.invoiceAmount)
                        : CurrencyDefaults.format(0)}
                </div>
            }
            {showInvoiceNumber &&
              <div className={hasWrite ? 'clickable' : ''}
                   onClick={() => showMonthModal(campaignGroupMonth, row.company, row.campaign, row.platform, month)}>
                    Invoice Number:&nbsp;
                    {campaignGroupMonth && campaignGroupMonth.actualExpense && campaignGroupMonth.actualExpense.invoiceNumber ? campaignGroupMonth.actualExpense.invoiceNumber : ''}
                </div>
            }
        </div>
    );
};

export default CampaignGroupCell;
