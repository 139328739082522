import { useState, useEffect } from 'react';
import '../css/template.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt, faUtensils, faSitemap, faFunnelDollar, faIndustry, faInfoCircle, faCommentDollar,
  faUser, faSignOutAlt, faChevronDown }
  from '@fortawesome/free-solid-svg-icons'
import 'core-js/actual/array/group';
import Config from '../config';
import AuthConfig from '../auth-config';
import ApiClient from '../api-client';
import { jwtDecode } from "jwt-decode";
import { Link } from 'react-router-dom';

function Template({children, selectedNavItem}) {
  const [roles, setRoles] = useState([]);
  const [userFullName, setUserFullName] = useState('');
  const [showUserOverlay, setShowUserOverlay] = useState(false);
  useEffect(() => {
    async function getUser() {
      let token = await new ApiClient().getToken();
      let decodedToken = jwtDecode(token);
      setUserFullName(decodedToken.name);
      setRoles(decodedToken.roles);
    }
    getUser();
  }, [setUserFullName]);
  function getNavButtonCss(path) {
    if (window.location.pathname === path) {
      return 'btn-orange';
    }
    return 'btn-outline-orange';
  }
  function getDropdownNavButtonCss(path) {
    if (window.location.pathname === path) {
      return 'active';
    }
    return '';
  }
  return (
      <div>
        <div className="sidebar-toggled media-expense-wrapper">
        <div id="wrapper">
          <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
            <li className="nav-item">
              <a className="sidebar-brand d-flex align-items-center justify-content-center" href="https://secure.datsvr.com/dashboard/">
                <div className="sidebar-brand-icon">
                  <img src="/img/rw-logo.png" alt="" role="presentation"/>
                </div>
                <div className="sidebar-brand-text mx-3">Data Server</div>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">
                <FontAwesomeIcon icon={faTachometerAlt}/>
                <span>Dashboard</span>
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href={Config.getDataServerUrl() + '/dashboard/flemings/'}>
                <FontAwesomeIcon icon={faUtensils}/>
                <span>Fleming's Maintenance</span>
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href={Config.getDataServerUrl() + '/dashboard/hosting/'}>
                <FontAwesomeIcon icon={faSitemap}/>
                <span>Hosted Websites</span>
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href={Config.getIoUrl() + '/overview/'} data-toggle="collapse" data-target="#collapse4" aria-expanded="false"
                 aria-controls="collapse4">
                <FontAwesomeIcon icon={faFunnelDollar} />
                <span>Insertion Orders</span>
              </a>
              <div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/ios/">Overview</a>
                  <a className="collapse-item "
                     href="https://secure.datsvr.com/dashboard/ios/integrations/">Integrations</a>
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/ios/setup/">Setup</a>
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/ios/trash/">Trash</a>
                </div>
              </div>
            </li>
            <li className={`nav-item ${selectedNavItem === 'media-expenses' && 'active'}`}>
              <a className="nav-link" href="/">
                <FontAwesomeIcon icon={faCommentDollar}/>
                <span>Paid Media Expenses</span>
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href={Config.getDataServerUrl() + '/dashboard/lms/'} data-toggle="collapse" data-target="#collapse5" aria-expanded="false"
                 aria-controls="collapse5">
                <FontAwesomeIcon icon={faIndustry} />
                <span>Lead Management</span>
              </a>
              <div id="collapse5" className="collapse" aria-labelledby="heading5" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/lms/">Overview</a>
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/lms/campaigns/">Campaigns</a>
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/lms/footprints/">Footprints</a>
                  <a className="collapse-item "
                     href="https://secure.datsvr.com/dashboard/lms/information/">Information</a>
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/lms/leads/">Leads</a>
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/lms/services/">Services</a>
                  <a className="collapse-item " href="https://secure.datsvr.com/dashboard/lms/sites/">Sites</a>
                </div>
              </div>
            </li>
            <li className={`nav-item ${selectedNavItem === 'system' && 'active'}`}>
              <Link 
               className="nav-link" 
               to="/system"
               data-toggle="collapse" 
               data-target="#collapse6" 
               aria-expanded="false"
               aria-controls="collapse6"
              >
               <FontAwesomeIcon icon={faInfoCircle}/>
               <span>System Information</span>
              </Link>
            </li>
          </ul>
          <div id="content-wrapper">
            <nav className="navbar navbar-expand">
              <div className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100">
                <ul className="nav nav-pills submenu">
                  {
                    (roles.includes('mxs.budget.read')) &&
                    <li className="nav-item">
                      <Link className={`nav-link btn ${getNavButtonCss('/budget')}`} to="/budget">
                        Budget
                      </Link>
                    </li>
                  }
                  {
                    roles.includes('mxs.pacing.read') &&
                    <li className="nav-item">
                      <Link className={`nav-link btn ${getNavButtonCss('/pacing')}`} to="/pacing">
                        Pacing
                      </Link>
                    </li>
                  }
                  {
                    roles.includes('mxs.write') &&
                    <li className="nav-item">
                      <div className="dropdown">
                        <div className="btn-wrapper">
                          <Link
                            to="/authoritative-actuals"
                            className={`btn btn-outline-orange
                              ${getDropdownNavButtonCss('/authoritative-actuals')}
                              ${getDropdownNavButtonCss('/authoritatve-actuals-details')}
                              ${getDropdownNavButtonCss('/reconciliation')}
                              ${getDropdownNavButtonCss('/import-batches')}`}
                          >
                            Authoritative Actuals <FontAwesomeIcon icon={faChevronDown}/>
                          </Link>
                        </div>
                        <ul>
                          <li className="nav-item">
                            <Link className={`nav-link`} to="/reconciliation">
                              Reconciliation
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link`} to="/authoritatve-actuals-details">
                              Details
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link`} to="/import-batches">
                              Import Batches
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  }
                  {(roles.includes('mxs.write') || roles.includes('mxs.margins.read')) &&
                    <li className="nav-item">
                      <div className="dropdown">
                        <p className={`btn btn-outline-orange
                         ${getDropdownNavButtonCss('/margins')}
                         ${getDropdownNavButtonCss('/management-fees')}
                         ${getDropdownNavButtonCss('/rollover-eligibility')}
                         ${getDropdownNavButtonCss('/payment-type-assignments')}
                         ${getDropdownNavButtonCss('/audit-trail')}`}>Setup <FontAwesomeIcon icon={faChevronDown}/>
                        </p>
                        <ul>
                          {roles.includes('mxs.margins.read') &&
                            <li>
                              <Link className={`nav-link`} to="/margins">
                                Margins
                              </Link>
                            </li>
                          }
                          {roles.includes('mxs.write') &&
                            <li>
                              <Link className={`nav-link`} to="/management-fees">
                                Management Fees
                              </Link>
                            </li>
                          }
                          {roles.includes('mxs.write') &&
                            <li>
                              <Link className={`nav-link`} to="/rollover-eligibility">
                                Rollover Eligibility
                              </Link>
                            </li>
                          }
                          {roles.includes('mxs.write') &&
                            <li>
                              <Link className={`nav-link`} to="/payment-type-assignments">
                                Payment Type Assignments
                              </Link>
                            </li>
                          }
                          {roles.includes('mxs.write') &&
                            <li>
                              <Link className={`nav-link`} to="/audit-trail">
                                Audit Trail
                              </Link>
                            </li>
                          }
                        </ul>
                      </div>
                    </li>
                  }
                </ul>
              </div>
              <ul className="navbar-nav ml-auto"
                  onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      setShowUserOverlay(false)
                    }
                  }}>
                <li className="nav-item dropdown no-arrow">
                  <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                     onClick={(e) => setShowUserOverlay(!showUserOverlay)}>
                    <span className="mr-2 d-none d-lg-inline text-gray-900 small">Welcome, {userFullName}</span>
                    <FontAwesomeIcon icon={faUser}/>
                  </a>
                  <div
                    className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${showUserOverlay && 'show'}`}
                    aria-labelledby="userDropdown">
                    <a className="dropdown-item" href="/profile">
                      <FontAwesomeIcon icon={faUser} className="fa-sm fa-fw mr-2"/>
                      My Profile
                    </a>
                    <a
                      className="dropdown-item" href="#"
                      onClick={async () => {
                        let publicClientApplication = await AuthConfig.getPublicClientApplication();
                        publicClientApplication.logoutRedirect()
                      }
                    }>
                      <FontAwesomeIcon icon={faSignOutAlt} className="fa-sm fa-fw mr-2" />
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div id="content">
              {children}
            </div>
            <footer className="sticky-footer">
                <div className="copyright">
                  <p data-commit-hash={process.env.REACT_APP_COMMIT_HASH} data-pipeline-id={process.env.REACT_APP_PIPELINE_URL}>Copyright © {new Date().getFullYear()} Roger West, LLC.</p>
                </div>
            </footer>
          </div>
        </div>
        </div>
      </div>
  );
}

export default Template;
