import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const TooltipLabel = ({ 
	label, 
	tooltipId, 
	children,
	place = "right"
}) => {
	return (
		<div className="d-flex align-items-center mb-1">
			<strong className="me-2">{label}&nbsp;</strong>
			<FontAwesomeIcon
				icon={faCircleQuestion}
				className="text-primary cursor-pointer"
				data-tooltip-id={tooltipId}
				style={{cursor: 'pointer'}}
				tabIndex="-1"
			/>
			<Tooltip id={tooltipId} place={place} clickable={true}>
				{children}
			</Tooltip>
		</div>
	);
};

export default TooltipLabel;