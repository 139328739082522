import ReactSelect from 'react-select';

function ManagementFeeModal(props) {
    let companySelectData = props.companies.map(x => ({ label: x.name, value: x.id }));
    companySelectData.unshift({ label: 'Any', value: 0 });

    return <div>
        {props.model.visible &&
            <div>
                <div className="modal fade show" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     style={{display: 'block'}} aria-modal="true" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {props.model.data.id ? 'Edit' : 'Add'} Management Fee
                                </h5>
                                <button type="button" className="btn-close" onClick={() => props.setModel({...props.model, visible: false})}></button>
                            </div>
                            <div className="modal-body">
                                {props.model.errorMessage &&
                                    <div className="col-xl-12">
                                        <div className={`alert alert-dismissible alert-danger`} role="alert">
                                            {props.model.errorMessage}
                                        </div>
                                    </div>
                                }
                                <div>
                                    <strong>Company</strong>
                                    <div>
                                        <ReactSelect
                                            className="react-select"
                                            options={companySelectData}
                                            isSearchable={true}
                                            defaultValue={() => {
                                                let companyId = props.model.data.company ? props.model.data.company.id : 0;
                                                return companySelectData.find(x => x.value === companyId);
                                            }}
                                            onChange={(option) => {
                                                props.model.data.company = option.value > 0 ? { id: option.value } : null;
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <strong>Percentage</strong>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={props.model.percentageText || ''}
                                        onChange={e => props.setModel({...props.model, percentageText: e.target.value})}
                                    />
                                </div>
                                <div className="mt-3">
                                    <strong>Minimum</strong>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={props.model.minimumText || ''}
                                        onChange={e => props.setModel({...props.model, minimumText: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => props.setModel({...props.model, visible: false})}>
                                    Cancel
                                </button>
                                <button type="button" className="btn btn-primary" onClick={() => props.onSave(props.model.data)}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>
        }
    </div>;
}

export default ManagementFeeModal;
