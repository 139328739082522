import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ApiClient from '../api-client';
import Template from './template';
import '../css/floating-labels.css';
import '../css/payment-type-assignments.css';
import 'react-tooltip/dist/react-tooltip.css'
import PaymentTypeAssignmentModal from '../components/payment-type-assignment-modal';
import {toast} from "react-toastify";

function PaymentTypeAssignments(props) {
    const [assignmentData, setAssignmentData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [assignmentModalModel, setAssignmentModalModel] = useState({visible: false});
    const [deleteConfirmation, setDeleteConfirmation] = useState({
        isOpen: false,
        assignment: null
    });

    const columns = [
        {
            name: 'Company',
            selector: row => getCompanyText(row),
            sortable: true
        },
        {
            name: 'Platform',
            selector: row => getPlatformText(row),
            sortable: true
        },
        {
            name: 'Payment Type',
            selector: row => row.paymentType
        },
        {
            name: '',
            cell: (row, index, column) => {
                return <span
                    onClick={() => {
                        setAssignmentModalModel({
                            visible: true,
                            data: row
                        });
                    }}
                    className="clickable">Edit</span>
            }
        },
        {
            name: '',
            cell: (row, index, column) => {
                return <span 
                    onClick={() => setDeleteConfirmation({ isOpen: true, assignment: row })} 
                    className="clickable"
                >
                    Delete
                </span>
            }
        }
    ];

    useEffect(() => {
        async function fetchData() {
            try {
                let companiesPromise = new ApiClient().fetch(`/Campaign/companies`, 'GET', null, false);
                let platformsPromise = new ApiClient().fetch(`/Campaign/platforms`, 'GET', null, false);
                let assignmentsPromise = refreshAssignments();

                let companies = await companiesPromise;
                let platforms = await platformsPromise;
                await assignmentsPromise;

                setCompanies(companies);
                setPlatforms(platforms);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    }, [setAssignmentData]);

    function getAssignmentDescription(assignment) {
        return getCompanyText(assignment) + '/' + getPlatformText(assignment);
    }

    function getCompanyText(assignment) {
        return assignment.company ? assignment.company.name : '';
    }

    function getPlatformText(assignment) {
        if (!assignment.platform) {
            return '';
        }
        let platformName = (assignment.platform.description || '').toLowerCase() === (assignment.platform.name || '').toLowerCase()
            ? assignment.platform.description
            : `${assignment.platform.description} - ${assignment.platform.name}`;
        return platformName;
    }

    async function refreshAssignments() {
        setTableLoading(true);
        try {
            let assignments = await new ApiClient().fetch(`/Setup/payment-type-assignments`, 'GET', null, false);
            setAssignmentData(assignments);
            setTableLoading(false);
        } catch (error) {
            toast.error(error, { autoClose: false});
        }
    }

    async function addOrUpdateAssignment(model) {
        let validationText = getAssignmentValidation(model);
        if (validationText.length > 0) {
            setAssignmentModalModel({...assignmentModalModel, errorMessage: validationText });
            return;
        }
        model.paymentType = model.paymentType.trim();
        try {
            let newOrUpdatedModel = await new ApiClient().fetch('/Setup/payment-type-assignments', 'POST', model, {loadingMessage: 'Saving', successMessage: 'Saved'});
            let assignmentDataCopy = model.id > 0
                ? assignmentData.filter(x => x.id !== model.id)
                : [...assignmentData];
            assignmentDataCopy.push(newOrUpdatedModel);
            setAssignmentData(assignmentDataCopy);
            setAssignmentModalModel({...assignmentModalModel, visible: false });
            await refreshAssignments();
        } catch (error) {
            // Api client handles messaging.
        }
    }

    async function deleteAssignment(assignment) {
        try {
            await new ApiClient().fetch(`/Setup/${assignment.id}`, 'DELETE', null, {loadingMessage: 'Deleting', successMessage: 'Deleted'});
            setAssignmentData(assignmentData.filter(x => x.id !== assignment.id));
            setDeleteConfirmation({ isOpen: false, assignment: null });
        } catch (error) {
            // Api client handles messaging.
        }
    }

    function getAssignmentValidation(assignmentModel) {
        console.log(assignmentModalModel);
        if (!assignmentModel?.paymentType) {
            return 'Payment type is required';
        }
        if (!assignmentModel?.company?.id) {
            return 'Company is required';
        }
        if (!assignmentModel?.platform?.id) {
            return 'Platform is required';
        }
        let assignmentMatch = assignmentData.find(x =>
            (x.company?.id ?? 0) === (assignmentModel.company?.id ?? 0) &&
            (x.platform?.id ?? 0) === (assignmentModel.platform?.id ?? 0) &&
            assignmentModel.id !== x.id
        );
        if (assignmentMatch) {
            return `Assignment for ${getAssignmentDescription(assignmentMatch)} already exists`;
        }
        return '';
    }

    function showAddNewAssignment() {
        setAssignmentModalModel({
            ...assignmentModalModel,
            visible: true,
            data: {}
        });
    }

    return <Template selectedNavItem="media-expenses">
        <div className="container-fluid payment-type-assignments-screen">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h4 className="m-0 font-weight-bold">Payment Type Assignments</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12">
                                    <button onClick={showAddNewAssignment} className="btn btn-primary add-new-assignment btn-header">Add New Assignment</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <DataTable
                                        columns={columns}
                                        data={assignmentData}
                                        striped="true"
                                        highlightOnHover="true"
                                        dense="true"
                                        progressPending={tableLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            <PaymentTypeAssignmentModal
                model={assignmentModalModel}
                companies={companies}
                platforms={platforms}
                updateModel={(updated) => setAssignmentModalModel(updated)}
                cancel={() => setAssignmentModalModel({visible: false, data: null})}
                save={() => {
                    addOrUpdateAssignment(assignmentModalModel.data);
                }}
            />
        }
        {deleteConfirmation.isOpen && (
            <div className="transfer-modal">
                <div>
                    <div className="modal fade show" tabIndex="-1" aria-labelledby="deleteModalLabel"
                         style={{display: 'block'}} aria-modal="true" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteModalLabel">
                                        Confirm Delete
                                    </h5>
                                    <div style={{cursor: 'default'}}>
                                        <button
                                            onClick={() => setDeleteConfirmation({ isOpen: false, assignment: null })}
                                            className="close" type="button" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete the payment type assignment for {getAssignmentDescription(deleteConfirmation.assignment)}?</p>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button 
                                        onClick={() => setDeleteConfirmation({ isOpen: false, assignment: null })}
                                        className="btn btn-outline-orange"
                                        data-dismiss="modal">
                                        Cancel
                                    </button>
                                    <button 
                                        className="btn btn-danger"
                                        onClick={() => deleteAssignment(deleteConfirmation.assignment)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            </div>
        )}
    </Template>;
}

export default PaymentTypeAssignments;