import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import ReactSelect from 'react-select';

function PaymentTypeAssignmentModal(props) {
    const companySelectData = props.companies.map(x => ({ label: x.name, value: x.id }));
    const platformSelectData = props.platforms.map(x => ({ label: `${x.description} - ${x.name}`, value: x.id }));
    return <div>
        {props.model.visible &&
            <div>
                <div className="modal fade show" id="logoutModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     style={{display: 'block'}} aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Payment Type Assignment
                                </h5>
                                <div style={{cursor: 'default'}}>
                                    <span
                                        data-tooltip-id="history-tooltip"
                                        data-tooltip-place="bottom"
                                    >
                                        History
                                        <FontAwesomeIcon icon={faCircleInfo} style={{
                                            marginRight: '15px',
                                            marginLeft: '3px',
                                            fontSize: '.8rem'
                                        }}/>
                                    </span>
                                    <Tooltip id="history-tooltip">
                                        <div>Last Updated: {props.model.data ? new Date(Date.parse(props.model.data.updateDateUtc)).toLocaleString() : ''}</div>
                                        <div>Updated By: {props.model.data ? props.model.data.updatedBy : ''}</div>
                                    </Tooltip>
                                    <button
                                        onClick={props.cancel}
                                        className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {props.model.errorMessage &&
                                        <div className="col-xl-12">
                                            <div className={`alert alert-dismissible alert-danger`} role="alert">
                                                {props.model.errorMessage}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <strong>Company</strong>
                                    <div>
                                        <ReactSelect
                                            className="react-select"
                                            options={companySelectData}
                                            isSearchable={true}
                                            defaultValue={() => {
                                                let companyId = props.model.data.company ? props.model.data.company.id : 0;
                                                return companySelectData.find(x => x.value === companyId);
                                            }}
                                            onChange={(option) => {
                                                props.model.data.company = option ? { id: option.value } : null;
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <strong>Platform</strong>
                                    <div>
                                        <ReactSelect
                                            className="react-select"
                                            options={platformSelectData}
                                            isSearchable={true}
                                            defaultValue={ () => {
                                                let platformId = props.model.data.platform ? props.model.data.platform.id : 0;
                                                return platformSelectData.find(x => x.value === platformId);
                                            }}
                                            onChange={(option) => {
                                                props.model.data.platform = option ? { id: option.value } : null;
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <strong>Payment Type</strong>
                                    <input type="text"
                                        className="form-control"
                                        value={props.model.data.paymentType || ''}
                                        onChange={e => {
                                            props.updateModel({
                                                ...props.model,
                                                data: { ...props.model.data, paymentType: e.target.value }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button onClick={props.cancel} className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary" onClick={props.save}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>
        }
    </div>;
}

export default PaymentTypeAssignmentModal;