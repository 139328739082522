import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CurrencyDefaults from '../currency-defaults';
import ReactSelect from 'react-select';
import ApiClient from '../api-client';
import Template from './template';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import '../css/authoritative-actuals.css';
import Config from '../config';

function AuthoritativeActuals() {
	const getDefaultDates = () => {
		const today = new Date();
		const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
		const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
		return {
			start: firstDayLastMonth.toISOString().split('T')[0],
			end: lastDayLastMonth.toISOString().split('T')[0]
		};
	};

	const fundingOptions = [
		{ value: 0, label: Config.getFundingDescription(0) },
		{ value: 1, label: Config.getFundingDescription(1) }
	];

	const defaultDates = getDefaultDates();
	const [companySelectValue, setCompanySelectValue] = useState('');
	const [companySelectData, setCompanySelectData] = useState([]);
	const [platformSelectValue, setPlatformSelectValue] = useState([]);
	const [platformSelectData, setPlatformSelectData] = useState([]);
	const [startDate, setStartDate] = useState(defaultDates.start);
	const [endDate, setEndDate] = useState(defaultDates.end);
	const [actuals, setActuals] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tableLoading, setTableLoading] = useState(true);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [csvFile, setCsvFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showPdfUploadModal, setShowPdfUploadModal] = useState(false);
	const [pdfFile, setPdfFile] = useState(null);
	const [isPdfUploading, setIsPdfUploading] = useState(false);
	const [pdfAmount, setPdfAmount] = useState('');
	const [pdfInvoiceNumber, setPdfInvoiceNumber] = useState('');
	const [pdfInvoiceDate, setPdfInvoiceDate] = useState(defaultDates.end);
	const [pdfCompanies, setPdfCompanies] = useState([]);
	const [pdfPlatforms, setPdfPlatforms] = useState([]);
	const [selectedPdfCompany, setSelectedPdfCompany] = useState(null);
	const [selectedPdfPlatform, setSelectedPdfPlatform] = useState(null);
	const [selectedPdfFunding, setSelectedPdfFunding] = useState(null);

	const columns = [
		{
			name: 'Company',
			selector: row => row.company.name,
			sortable: true
		},
		{
			name: 'Amount',
			selector: row => row.totalAmount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.totalAmount)}</div>,
			width: '110px',
			sortable: true
		},
		{
			name: 'Transaction Count',
			selector: row => row.transactionCount,
			cell: row => <div className="right-align-column">{row.transactionCount}</div>,
			width: '100px',
			sortable: true
		},
		{
			name: 'Authority Type',
			selector: row => row.authorityTypes,
			sortable: true
		}
	];

	useEffect(() => {
		setDataAndApplyFilters(actuals);
	}, [companySelectValue, platformSelectValue]);

	useEffect(() => {
		async function fetchData() {
			try {
				setTableLoading(true);
				const data = await new ApiClient().fetch(`/Campaign/authoritative-actuals/summary?startDate=${startDate}&endDate=${endDate}`, 'GET');
				setActuals(data);
				setTableLoading(false);
				setDataAndApplyFilters(data);
			} catch (error) {
				// Toast handles errors
			}
		}
		fetchData();
	}, [startDate, endDate]);

	function setDataAndApplyFilters(data) {
		let filteredData = data.filter(actual => {
			const companyMatch = !companySelectValue || actual.company.name === companySelectValue;
			const platformMatch = !platformSelectValue.length || getPlatformText(actual.platform) === platformSelectValue;
			
			return companyMatch && platformMatch;
		});

		// Group by company
		const groupedData = filteredData.reduce((acc, row) => {
			const key = row.company.id;
			if (!acc[key]) {
				acc[key] = {
					id: key,
					company: row.company,
					totalAmount: CurrencyDefaults.factory(0),
					transactionCount: 0,
					authorityTypes: row.authorityTypes,
					platforms: {}
				};
			}
			
			// Add platform details
			const platformKey = row.platform.id;
			if (!acc[key].platforms[platformKey]) {
				acc[key].platforms[platformKey] = {
					platform: row.platform,
					totalAmount: CurrencyDefaults.factory(0),
					transactionCount: 0,
					authorityTypes: row.authorityTypes
				};
			}

			// Sum up totals for both company and platform levels
			[acc[key], acc[key].platforms[platformKey]].forEach(target => {
				target.totalAmount = target.totalAmount.add(row.totalAmount);
				target.transactionCount += row.transactionCount;
			});

			return acc;
		}, {});

		const aggregatedData = Object.values(groupedData);
		setFilters(filteredData);
		setTableData(aggregatedData);
	}

	function setFilters(data) {
		const uniqueCompanies = Array.from(new Set(data.map(x => x.company.name)));
		uniqueCompanies.sort();
		setCompanySelectData(uniqueCompanies.map(x => ({ label: x, value: x })));
		
		const uniquePlatformIds = Array.from(new Set(data.map(x => x.platform.id)));
		const uniquePlatforms = Array.from(uniquePlatformIds.map(x => data.find(y => x === y.platform.id).platform));
		uniquePlatforms.sort((a, b) => (getPlatformText(a) > getPlatformText(b)) ? 1 : -1);
		setPlatformSelectData(uniquePlatforms.map(x => ({ label: getPlatformText(x), value: x.id })));
	}

	function getPlatformText(platform) {
		if (!platform) {
			return 'Any';
		}
		return platform.description === platform.name.toLowerCase() 
			? platform.description 
			: `${platform.description} - ${platform.name}`;
	}

	const handleFileChange = (event) => {
		setCsvFile(event.target.files[0]);
	};

	const handleUpload = async () => {
		if (!csvFile) {
			toast.error('Please select a file to upload');
			return;
		}
		setIsUploading(true);
		const formData = new FormData();
		formData.append('csvFile', csvFile);
		try {
			await new ApiClient().fetch(
				`/Campaign/upload-authoritative-actuals-csv`,
				'POST',
				formData,
				{loadingMessage: 'Uploading', successMessage: 'Uploaded'}
			);
			const data = await new ApiClient().fetch(
				`/Campaign/authoritative-actuals/summary?startDate=${startDate}&endDate=${endDate}`,
				'GET'
			);
			setShowUploadModal(false);
			setCsvFile(null);
			setActuals(data);
			setDataAndApplyFilters(data);
		} catch {
			// ApiClient handles errors.
		} finally {
			setIsUploading(false);
		}
	};

	const handlePdfFileChange = (event) => {
		setPdfFile(event.target.files[0]);
	};

	const handlePdfAmountChange = (e) => {
		setPdfAmount(e.target.value);
	};

	const handlePdfInvoiceNumberChange = (e) => {
		setPdfInvoiceNumber(e.target.value);
	};

	const handlePdfInvoiceDateChange = (e) => {
		setPdfInvoiceDate(e.target.value);
	};

	useEffect(() => {
		async function fetchPdfModalData() {
			if (showPdfUploadModal) {
				try {
					const [companies, platforms] = await Promise.all([
						new ApiClient().fetch('/Campaign/companies', 'GET'),
						new ApiClient().fetch('/Campaign/platforms', 'GET')
					]);
					
					setPdfCompanies(companies.map(c => ({ 
						value: c.id, 
						label: c.name 
					})));
					
					setPdfPlatforms(platforms.map(p => ({ 
						value: p.id, 
						label: getPlatformText(p)
					})));
				} catch {
					// ApiClient handles errors
				}
			}
		}
		fetchPdfModalData();
	}, [showPdfUploadModal]);

	const resetPdfModal = () => {
		setPdfFile(null);
		setSelectedPdfCompany(null);
		setSelectedPdfPlatform(null);
		setSelectedPdfFunding(null);
		setPdfInvoiceNumber('');
		setPdfInvoiceDate(defaultDates.end);
		setPdfAmount('');
		setErrorMessage('');
	};

	const handleShowPdfModal = () => {
		resetPdfModal();
		setShowPdfUploadModal(true);
	};

	const handleClosePdfModal = () => {
		resetPdfModal();
		setShowPdfUploadModal(false);
	};

	const handlePdfUpload = async () => {
		setErrorMessage('');
		
		if (!pdfFile) {
			setErrorMessage('Please select a file to upload');
			return;
		}

		if (!selectedPdfCompany) {
			setErrorMessage('Please select a company');
			return;
		}

		if (!selectedPdfPlatform) {
			setErrorMessage('Please select a platform');
			return;
		}

		if (!selectedPdfFunding || selectedPdfFunding.value === null) {
			setErrorMessage('Please select a funding type');
			return;
		}

		if (!pdfInvoiceNumber.trim()) {
			setErrorMessage('Please enter an invoice number');
			return;
		}

		if (!pdfInvoiceDate) {
			setErrorMessage('Please select an invoice date');
			return;
		}

		const amount = parseFloat(pdfAmount);
		if (!pdfAmount || isNaN(amount)) {
			setErrorMessage('Please enter a valid amount');
			return;
		}

		const decimalPart = amount.toString().split('.')[1] || '';
		if (decimalPart.length > 2) {
			setErrorMessage('Amount cannot have more than 2 decimal places');
			return;
		}

		if (Math.abs(amount) < 0.01) {
			setErrorMessage('Amount must be at least $0.01');
			return;
		}

		setIsPdfUploading(true);
		const formData = new FormData();
		formData.append('pdfFile', pdfFile);
		formData.append('amount', pdfAmount);
		formData.append('invoiceNumber', pdfInvoiceNumber);
		formData.append('invoiceDate', pdfInvoiceDate);
		formData.append('companyId', selectedPdfCompany.value);
		formData.append('platformId', selectedPdfPlatform.value);
		formData.append('funding', selectedPdfFunding.value);
		
		try {
			await new ApiClient().fetch(
				`/Campaign/upload-authoritative-actuals-pdf`,
				'POST',
				formData,
				{loadingMessage: 'Uploading', successMessage: 'Uploaded'}
			);
			const data = await new ApiClient().fetch(
				`/Campaign/authoritative-actuals/summary?startDate=${startDate}&endDate=${endDate}`,
				'GET'
			);
			setShowPdfUploadModal(false);
			setPdfFile(null);
			setActuals(data);
			setDataAndApplyFilters(data);
		} catch {
			// ApiClient handles errors.
		} finally {
			setIsPdfUploading(false);
		}
	};

	const expandableRowsComponent = ({ data }) => {
		const platforms = Object.values(data.platforms);
		return (
			<div className="p-3">
				<table className="table table-sm table-striped">
					<thead>
						<tr>
							<th className="text-dark">Platform</th>
							<th className="text-dark text-right">Amount</th>
							<th className="text-dark text-right">Transaction Count</th>
							<th className="text-dark">Authority Type</th>
						</tr>
					</thead>
					<tbody>
						{platforms.map((platform, index) => (
							<tr key={index}>
								<td className="text-dark">{platform.platform.name}</td>
								<td className="text-dark text-right">{CurrencyDefaults.formatFixed(platform.totalAmount)}</td>
								<td className="text-dark text-right">{platform.transactionCount}</td>
								<td className="text-dark">{platform.authorityTypes}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	return <Template selectedNavItem="media-expenses">
		<div className="authoritative-actuals-screen">
			<div className="row">
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Start Date</label>
						<input
							type="date"
							className="form-control date-control"
							value={startDate}
							onChange={e => setStartDate(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">End Date</label>
						<input
							type="date"
							className="form-control date-control"
							value={endDate}
							onChange={e => setEndDate(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Company</label>
						<ReactSelect
							className="react-select"
							options={companySelectData}
							isSearchable={true}
							isClearable={true}
							placeholder="Company"
							onChange={(option) => setCompanySelectValue(option ? option.label : '')}
						/>
					</div>
				</div>
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Platform</label>
						<ReactSelect
							className="react-select"
							options={platformSelectData}
							isSearchable={true}
							isClearable={true}
							placeholder="Platform"
							onChange={(option) => setPlatformSelectValue(option ? option.label : '')}
						/>
					</div>
				</div>
				<div className="col-xl-2">
					<div className="form-group mb-0">
						<label className="small mb-1">&nbsp;</label>
						<button
							onClick={() => setShowUploadModal(true)}
							className="btn btn-orange btn-upload w-100"
							disabled={tableLoading}
						>
							<FontAwesomeIcon className="mr-2" icon={faFileCsv} />
							Upload Credit Card CSV
						</button>
					</div>
				</div>
				<div className="col-xl-2">
					<div className="form-group mb-0">
						<label className="small mb-1">&nbsp;</label>
						<button
							onClick={handleShowPdfModal}
							className="btn btn-orange btn-upload w-100"
							disabled={tableLoading}
						>
							<FontAwesomeIcon className="mr-2" icon={faFilePdf} />
							Upload Invoice PDF
						</button>
					</div>
				</div>
			</div>
			<hr/>
			<div className="row">
				<div className="col-xl-12">
					<div className="mb-3">
						<div className="card shadow mb-2">
							<div className="card-body py-2">
								<div className="row align-items-start g-3">
									<div className="col-12 col-sm-auto mt-1 me-2">
										<span className="font-weight-bold text-dark fw-bold">Grand Totals:</span>
									</div>
									<div className="col">
										<table className="table table-sm mb-0 border-bottom">
											<thead>
											<tr>
												<th className="text-dark text-center">Amount</th>
												<th className="text-dark text-center">Transaction Count</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td className="text-dark text-center">
													{CurrencyDefaults.formatFixed(tableData.reduce((sum, row) =>
														sum.add(row.totalAmount), CurrencyDefaults.factory(0)))}
												</td>
												<td className="text-dark text-center">
													{tableData.reduce((sum, row) => sum + row.transactionCount, 0)}
												</td>
											</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<DataTable
						columns={columns}
						data={tableData}
						striped={true}
						highlightOnHover={true}
						dense={true}
						pagination={true}
						paginationPerPage={100}
						paginationRowsPerPageOptions={[20, 50, 100]}
						expandableRows
						expandableRowsComponent={expandableRowsComponent}
						expandOnRowClicked
						paginationComponentOptions={{
							selectAllRowsItem: true
						}}
						progressPending={tableLoading}
						responsive={true}
						customStyles={{
							cells: {
								style: {
									padding: '5px'
								},
							},
							headCells: {
								style: {
									paddingLeft: '5px',
									paddingRight: '5px',
									'& div': {
										overflow: 'visible',
										whiteSpace: 'normal',
										width: '100%',
										textAlign: 'center'
									},
								},
							}
						}}
					/>
				</div>
			</div>
		</div>
		{showUploadModal && (
			<>
				<div className="modal modal-scrollable fade show" style={{display: 'block'}} tabIndex="-1">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Upload Credit Card CSV</h5>
								<div style={{cursor: 'default'}}>
									<button
										type="button"
										className="close"
										onClick={() => {
											setShowUploadModal(false);
											setCsvFile(null);
										}}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							</div>
							<div className="modal-body">
								<div className="p-3">
									<div className="row">
										<div className="col-12">
											<strong className="d-block mb-1">Upload CSV file</strong>
											<input
												type="file"
												accept=".csv"
												className=""
												onChange={handleFileChange}
												disabled={isUploading}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer justify-content-between">
								<button
									type="button"
									className="btn btn-outline-orange"
									onClick={() => {
										setShowUploadModal(false);
										setCsvFile(null);
									}}
									disabled={isUploading}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn btn-orange"
									onClick={handleUpload}
									disabled={!csvFile || isUploading}
								>
									{isUploading ? 'Uploading...' : 'Upload'}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop fade show"></div>
			</>
		)}
		{showPdfUploadModal && (
			<>
				<div className="modal modal-scrollable fade show" style={{display: 'block'}} tabIndex="-1">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Upload Invoice PDF</h5>
								<div style={{cursor: 'default'}}>
									<button
										type="button"
										className="close"
										onClick={handleClosePdfModal}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							</div>
							<div className="modal-body">
								{errorMessage && 
									<div className="alert alert-danger" role="alert">
										{errorMessage}
									</div>
								}
								<div className="p-3">
									<div className="row">
										<div className="col-12 mb-3">
											<strong className="d-block mb-1">Upload Invoice PDF</strong>
											<input
												type="file"
												className=""
												onChange={handlePdfFileChange}
												accept=".pdf"
												disabled={isPdfUploading}
											/>
										</div>
										<div className="col-12 mb-3">
											<strong className="d-block mb-1">Company</strong>
											<ReactSelect
												className="react-select"
												options={pdfCompanies}
												value={selectedPdfCompany}
												onChange={setSelectedPdfCompany}
												isSearchable={true}
												placeholder="Select company"
												isDisabled={isPdfUploading}
											/>
										</div>
										<div className="col-12 mb-3">
											<strong className="d-block mb-1">Platform</strong>
											<ReactSelect
												className="react-select"
												options={pdfPlatforms}
												value={selectedPdfPlatform}
												onChange={setSelectedPdfPlatform}
												isSearchable={true}
												placeholder="Select platform"
												isDisabled={isPdfUploading}
											/>
										</div>
										<div className="col-12 mb-3">
											<strong className="d-block mb-1">Funding</strong>
											<ReactSelect
												className="react-select"
												options={fundingOptions}
												value={selectedPdfFunding}
												onChange={setSelectedPdfFunding}
												isSearchable={false}
												placeholder="Select funding type"
												isDisabled={isPdfUploading}
											/>
										</div>
										<div className="col-12 mb-3">
											<strong className="d-block mb-1">Invoice Number</strong>
											<input
												type="text"
												className="form-control"
												value={pdfInvoiceNumber}
												onChange={handlePdfInvoiceNumberChange}
												placeholder="Enter invoice number"
												disabled={isPdfUploading}
											/>
										</div>
										<div className="col-12 mb-3">
											<strong className="d-block mb-1">Invoice Date</strong>
											<input
												type="date"
												className="form-control"
												value={pdfInvoiceDate}
												onChange={handlePdfInvoiceDateChange}
												disabled={isPdfUploading}
											/>
										</div>
										<div className="col-12">
											<strong className="d-block mb-1">Amount</strong>
											<input
												type="number"
												className="form-control"
												value={pdfAmount}
												onChange={handlePdfAmountChange}
												placeholder="Enter amount"
												disabled={isPdfUploading}
												step="0.01"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer justify-content-between">
								<button
									type="button"
									className="btn btn-outline-orange"
									onClick={handleClosePdfModal}
									disabled={isPdfUploading}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn btn-orange"
									onClick={handlePdfUpload}
									disabled={isPdfUploading}
								>
									{isPdfUploading ? 'Uploading...' : 'Upload'}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop fade show"></div>
			</>
		)}
	</Template>;
}

export default AuthoritativeActuals;