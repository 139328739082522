import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ApiClient from '../api-client';
import Template from './template';
import '../css/rollover-eligibility.css';
import Config from '../config';
import {toast} from "react-toastify";

function RolloverEligibility() {
    const [eligibilityData, setEligibilityData] = useState({
        eligibleCompanies: [],
        eligibleFundingTypes: []
    });
    const [tableLoading, setTableLoading] = useState(true);

    // Transform the API response into table rows combining both rules
    const getTableData = () => {
        const rows = [];
        
        // Add funding type rule (company wildcard)
        eligibilityData.eligibleFundingTypes.forEach(fundingType => {
            rows.push({
                id: `funding-${fundingType}`,
                company: { name: 'Any' },
                fundingType: fundingType
            });
        });

        // Add company specific rules (funding type wildcard)
        eligibilityData.eligibleCompanies.forEach(company => {
            rows.push({
                id: `company-${company.id}`,
                company: company,
                fundingType: null
            });
        });

        return rows;
    };

    const columns = [
        {
            name: 'Company',
            selector: row => row.company.name,
            sortable: true
        },
        {
            name: 'Funding Type',
            selector: row => row.fundingType === null ? 'Any' : Config.getFundingDescription(row.fundingType),
            sortable: true
        }
    ];

    useEffect(() => {
        async function fetchData() {
            setTableLoading(true);
            try {
                const data = await new ApiClient().fetch(`/Setup/rollover-eligibility`, 'GET');
                setEligibilityData(data);
                setTableLoading(false);
            } catch (error) {
                toast.error(error, { autoClose: false });
            }
        }
        fetchData();
    }, []);

    return (
        <Template selectedNavItem="media-expenses">
            <div className="container-fluid rollover-eligibility-screen">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h4 className="m-0 font-weight-bold">Rollover Eligibility</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <DataTable
                                            columns={columns}
                                            data={getTableData()}
                                            striped={true}
                                            highlightOnHover={true}
                                            dense={true}
                                            progressPending={tableLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    );
}

export default RolloverEligibility;