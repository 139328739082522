import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CurrencyDefaults from '../currency-defaults';
import ReactSelect from 'react-select';
import ApiClient from '../api-client';
import Template from './template';
import '../css/authoritative-actuals-details.css';
import Config from '../config';
import { toast } from 'react-toastify';

function AuthoritativeActualsDetails() {
	const getDefaultDates = () => {
		const today = new Date();
		const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
		const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
		return {
			start: firstDayLastMonth.toISOString().split('T')[0],
			end: lastDayLastMonth.toISOString().split('T')[0]
		};
	};

	const defaultDates = getDefaultDates();
	const [companySelectValue, setCompanySelectValue] = useState('');
	const [companySelectData, setCompanySelectData] = useState([]);
	const [platformSelectValue, setPlatformSelectValue] = useState('');
	const [platformSelectData, setPlatformSelectData] = useState([]);
	const [startDate, setStartDate] = useState(defaultDates.start);
	const [endDate, setEndDate] = useState(defaultDates.end);
	const [originalData, setOriginalData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [tableLoading, setTableLoading] = useState(true);

	const handleFileClick = async (row) => {
		try {
			const extension = row.sourceFileName.split('.').pop().toLowerCase();
			const url = `${Config.getApiURL()}/Campaign/download-authoritative-actuals-document/${row.id}`;
			const token = await new ApiClient().getToken();

			if (extension === 'pdf') {
				// Open loading window first
				const loadingUrl = URL.createObjectURL(new Blob(['Loading...'], { type: 'text/plain' }));
				const newWindow = window.open(loadingUrl, '_blank');

				// Fetch PDF
				const response = await fetch(url, {
					headers: {
						'Authorization': 'Bearer ' + token
					}
				});

				if (!response.ok) {
					throw new Error('Failed to fetch PDF');
				}

				const blob = await response.blob();
				const blobUrl = URL.createObjectURL(blob);
				newWindow.location.href = blobUrl;
			} else {
				// For non-PDF files, trigger direct download
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', row.sourceFileName);
				link.setAttribute('target', '_blank');
				
				// Add auth header via fetch
				const response = await fetch(url, {
					headers: {
						'Authorization': 'Bearer ' + token
					}
				});

				if (!response.ok) {
					throw new Error('Failed to download file');
				}

				const blob = await response.blob();
				const blobUrl = URL.createObjectURL(blob);
				link.href = blobUrl;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(blobUrl);
			}
		} catch (error) {
			toast.error('Failed to open file', {
				autoClose: false,
				closeButton: true
			});
		}
	};

	const columns = [
		{
			name: 'File Name',
			selector: row => row.sourceFileName,
			cell: row => (
				<a 
					href="#" 
					onClick={(e) => {
						e.preventDefault();
						handleFileClick(row);
					}}
					className="text-primary"
				>
					{row.sourceFileName}
				</a>
			),
			sortable: true,
			width: '20%'
		},
		{
			name: 'Company',
			selector: row => row.company.name,
			sortable: true
		},
		{
			name: 'Platform',
			selector: row => row.platform.name,
			sortable: true
		},
		{
			name: 'Transaction Date',
			selector: row => row.transactionDate,
			cell: row => row.transactionDate,
			sortable: true
		},
		{
			name: 'Amount',
			selector: row => row.amount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.amount)}</div>,
			width: '110px',
			sortable: true
		},
		{
			name: 'Authority Type',
			selector: row => row.authorityType,
			sortable: true
		},
		{
			name: 'Reference ID',
			selector: row => row.referenceId,
			sortable: true
		},
		{
			name: 'Memo',
			selector: row => row.memo,
			sortable: true,
			grow: 2
		},
		{
			name: 'Created By',
			selector: row => row.createdBy,
			sortable: true
		},
		{
			name: 'Create Date',
			selector: row => row.createDateUtc,
			cell: row => new Date(row.createDateUtc).toLocaleDateString(),
			sortable: true
		}
	];

	useEffect(() => {
		async function fetchData() {
			try {
				setTableLoading(true);
				const data = await new ApiClient().fetch(`/Campaign/authoritative-actuals?startDate=${startDate}&endDate=${endDate}&dateFilterType=transactionDate`, 'GET');
				setOriginalData(data);
				setFilteredData(data);
				setFilters(data);
				setTableLoading(false);
			} catch (error) {
				setTableLoading(false);
			}
		}
		fetchData();
	}, [startDate, endDate]);

	useEffect(() => {
		const filteredData = originalData.filter(row => {
			const companyMatch = !companySelectValue || row.company.name === companySelectValue;
			const platformMatch = !platformSelectValue || row.platform.name === platformSelectValue;
			return companyMatch && platformMatch;
		});
		setFilteredData(filteredData);
	}, [companySelectValue, platformSelectValue, originalData]);

	function setFilters(data) {
		const uniqueCompanies = Array.from(new Set(data.map(x => x.company.name)));
		uniqueCompanies.sort();
		setCompanySelectData(uniqueCompanies.map(x => ({ label: x, value: x })));
		
		const uniquePlatforms = Array.from(new Set(data.map(x => x.platform.name)));
		uniquePlatforms.sort();
		setPlatformSelectData(uniquePlatforms.map(x => ({ label: x, value: x })));
	}

	return <Template selectedNavItem="media-expenses">
		<div className="authoritative-actuals-details-screen">
			<div className="row">
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Transaction Start Date</label>
						<input
							type="date"
							className="form-control date-control"
							value={startDate}
							onChange={e => setStartDate(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Transaction End Date</label>
						<input
							type="date"
							className="form-control date-control"
							value={endDate}
							onChange={e => setEndDate(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Company</label>
						<ReactSelect
							className="react-select"
							options={companySelectData}
							isSearchable={true}
							isClearable={true}
							placeholder="Company"
							onChange={(option) => setCompanySelectValue(option ? option.value : '')}
						/>
					</div>
				</div>
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Platform</label>
						<ReactSelect
							className="react-select"
							options={platformSelectData}
							isSearchable={true}
							isClearable={true}
							placeholder="Platform"
							onChange={(option) => setPlatformSelectValue(option ? option.value : '')}
						/>
					</div>
				</div>
			</div>
			<hr/>
			<div className="row">
				<div className="col-xl-12">
					<DataTable
						columns={columns}
						data={filteredData}
						striped={true}
						highlightOnHover={true}
						dense={true}
						pagination={true}
						paginationPerPage={100}
						paginationRowsPerPageOptions={[100,200,500]}
						paginationComponentOptions={{
							selectAllRowsItem: true
						}}
						progressPending={tableLoading}
						responsive={true}
						customStyles={{
							cells: {
								style: {
									padding: '5px'
								},
							},
							headCells: {
								style: {
									paddingLeft: '5px',
									paddingRight: '5px',
									'& div': {
										overflow: 'visible',
										whiteSpace: 'normal',
										width: '100%',
										textAlign: 'center'
									},
								},
							}
						}}
					/>
				</div>
			</div>
		</div>
	</Template>;
}

export default AuthoritativeActualsDetails;