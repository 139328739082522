import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CurrencyDefaults from '../currency-defaults';
import ReactSelect from 'react-select';
import ApiClient from '../api-client';
import Template from './template';
import '../css/import-batches.css';

function ImportBatches() {

	const getDefaultDates = () => {
		const today = new Date();
		const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
		const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		return {
			start: firstDayThisMonth.toISOString().split('T')[0],
			end: lastDayThisMonth.toISOString().split('T')[0]
		};
	};

	const defaultDates = getDefaultDates();
	const [startDate, setStartDate] = useState(defaultDates.start);
	const [endDate, setEndDate] = useState(defaultDates.end);
	const [paymentsData, setPaymentsData] = useState([]);
	const [batches, setBatches] = useState([]);
	const [tableLoading, setTableLoading] = useState(true);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [selectedBatch, setSelectedBatch] = useState(null);
	const [batchDetails, setBatchDetails] = useState([]);

	const columns = [
		{
			name: 'File Name',
			selector: row => row.fileName,
			sortable: true
		},
		{
			name: 'Import Type',
			selector: row => row.importType,
			cell: row => row.importType,
			width: '12%',
			sortable: true

		},
		{
			name: 'Import Date',
			selector: row => row.importDate,
			cell: row => new Date(row.importDate).toLocaleDateString(),
			width: '12%',
			sortable: true
		},
		{
			name: 'Date Range',
			selector: row => `${row.minDate} - ${row.maxDate}`,
			width: '12%',
			sortable: true
		},
		{
			name: 'Records',
			selector: row => row.recordCount,
			cell: row => <div className="right-align-column">{row.recordCount}</div>,
			width: '8%',
			sortable: true
		},
		{
			name: 'Total Amount',
			selector: row => row.totalAmount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.totalAmount)}</div>,
			width: '12%',
			sortable: true
		},
		{
			name: 'Created By',
			selector: row => row.createdBy,
			width: '12%',
			sortable: true
		}
	];

	const detailColumns = [
		{
			name: 'Row Num',
			selector: row => row.sourceRow,
			sortable: true,
			width: '80px',
		},
		{
			name: 'Company',
			selector: row => row.company.name,
			sortable: true
		},
		{
			name: 'Platform',
			selector: row => row.platform.name,
			sortable: true
		},
		{
			name: 'Transaction Date',
			selector: row => row.transactionDate,
			cell: row => row.transactionDate,
			sortable: true
		},
		{
			name: 'Amount',
			selector: row => row.amount,
			cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.amount)}</div>,
			width: '110px',
			sortable: true
		},
		{
			name: 'Authority Type',
			selector: row => row.authorityType,
			sortable: true
		},
		{
			name: 'Reference ID',
			selector: row => row.referenceId,
			sortable: true
		},
		{
			name: 'Memo',
			selector: row => row.memo,
			sortable: true,
			grow: 2
		}
	];

	useEffect(() => {
		async function fetchData() {
			try {
				setTableLoading(true);
				const data = await new ApiClient().fetch(`/Campaign/authoritative-actuals?startDate=${startDate}&endDate=${endDate}&dateFilterType=createDateUtc`, 'GET');
				setPaymentsData(data);
				
				// Group by filename and calculate summaries
				const groupedByFileName = data.reduce((acc, record) => {
					const key = record.sourceFileName;
					if (!acc[key]) {
						acc[key] = [];
					}
					acc[key].push(record);
					return acc;
				}, {});
				
				const batchSummaries = Object.entries(groupedByFileName).map(([fileName, records]) => ({
					fileName,
					importDate: records[0].createDateUtc,
					importType: records[0].authorityType,
					recordCount: records.length,
					totalAmount: records.reduce((sum, record) => sum.add(record.amount), CurrencyDefaults.factory(0)),
					createdBy: records[0].createdBy,
					company: records[0].company.name,
					platform: records[0].platform.name,
					minDate: records.reduce((min, record) => !min || record.transactionDate < min ? record.transactionDate : min, null),
					maxDate: records.reduce((max, record) => !max || record.transactionDate > max ? record.transactionDate : max, null)
				}));

				setBatches(batchSummaries);
				setTableLoading(false);
			} catch (error) {
				setTableLoading(false);
			}
		}
		fetchData();
	}, [startDate, endDate]);

	const handleRowClick = (row) => {
		const batchDetails = paymentsData.filter(payment => payment.sourceFileName === row.fileName);
		setBatchDetails(batchDetails);
		setSelectedBatch(row.fileName);
		setShowDetailsModal(true);
	};

	return <Template selectedNavItem="media-expenses">
		<div className="import-batches-screen">
			<div className="row">
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Import Start Date</label>
						<input
							type="date"
							className="form-control date-control"
							value={startDate}
							onChange={e => setStartDate(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-xl-2 mb-4 mb-xl-0">
					<div className="form-group mb-0">
						<label className="small mb-1">Import End Date</label>
						<input
							type="date"
							className="form-control date-control"
							value={endDate}
							onChange={e => setEndDate(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<hr/>
			<div className="row">
				<div className="col-xl-12">
					<DataTable
						columns={columns}
						data={batches}
						striped={true}
						highlightOnHover={true}
						dense={true}
						pagination={true}
						paginationPerPage={100}
						paginationRowsPerPageOptions={[20, 50, 100]}
						paginationComponentOptions={{
							selectAllRowsItem: true
						}}
						progressPending={tableLoading}
						responsive={true}
						onRowClicked={handleRowClick}
						pointerOnHover={true}
					/>
				</div>
			</div>

			{showDetailsModal && (
				<>
					<div className="modal modal-scrollable fade show" style={{display: 'block'}} tabIndex="-1">
						<div className="modal-dialog modal-xl" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Batch Details - {selectedBatch}</h5>
									<button
										type="button"
										className="close"
										onClick={() => setShowDetailsModal(false)}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<DataTable
										columns={detailColumns}
										data={batchDetails}
										striped={true}
										highlightOnHover={true}
										dense={true}
										pagination={true}
										paginationPerPage={100}
										paginationRowsPerPageOptions={[100,200,500]}
										customStyles={{
											cells: {
												style: {
													padding: '5px'
												},
											},
											headCells: {
												style: {
													paddingLeft: '5px',
													paddingRight: '5px',
													'& div': {
														overflow: 'visible',
														whiteSpace: 'normal',
														width: '100%',
														textAlign: 'center'
													},
												},
											}
										}}
									/>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-outline-orange"
										onClick={() => setShowDetailsModal(false)}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	</Template>;
}

export default ImportBatches;