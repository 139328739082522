import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ApiClient from '../api-client';
import Template from './template';
import '../css/audit-trail.css';
import {toast} from "react-toastify";

function AuditTrail(props) {

    const getDefaultDates = () => {
        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        return {
            start: thirtyDaysAgo.toISOString().split('T')[0],
            end: today.toISOString().split('T')[0]
        };
    };
    const defaultDates = getDefaultDates();

    const [mgmtFeeData, setMgmtFeeData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [startDate, setStartDate] = useState(defaultDates.start);
    const [endDate, setEndDate] = useState(defaultDates.end);

    const columns = [
        {
            name: 'Description',
            sortable: true,
            cell: (row, index, column) => {
                return <div>{row.description.split('\n').map((line, i) => (
                    <span key={i}>
                        {line}
                        {i < row.description.split('\n').length - 1 && <br />}
                    </span>
                ))}</div>
            },
            grow: 3
        },
        {
            name: 'Date',
            selector: row => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZoneName: "short"
                };
                return new Date(row.updateDate).toLocaleString('en-US', options);
            },
            sortable: true
        },
        {
            name: 'User',
            selector: row => row.updatedBy
        }
    ];

    useEffect(() => {
        async function fetchData() {
            setTableLoading(true);
            try {
                // Parse the date strings and construct proper local dates
                const [startYear, startMonth, startDay] = startDate.split('-').map(Number);
                const [endYear, endMonth, endDay] = endDate.split('-').map(Number);
                
                const fromDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0);
                const toDate = new Date(endYear, endMonth - 1, endDay, 23, 59, 59);

                let managementFees = await new ApiClient().fetch(
                    `/AuditTrail?from=${fromDate.toISOString()}&to=${toDate.toISOString()}`,
                    'GET'
                );
                setMgmtFeeData(managementFees);
                setTableLoading(false);
            } catch (error) {
            }
        }
        fetchData();
    }, [startDate, endDate]);

    return <Template selectedNavItem="media-expenses">
        <div className="container-fluid audit-trail-screen">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h4 className="m-0 font-weight-bold">Audit Trail</h4>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col-xl-2 mb-4 mb-xl-0">
                                    <div className="form-group mb-0">
                                        <label className="small mb-1">Start Date</label>
                                        <input
                                            type="date"
                                            className="form-control date-control"
                                            value={startDate}
                                            onChange={e => setStartDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 mb-4 mb-xl-0">
                                    <div className="form-group mb-0">
                                        <label className="small mb-1">End Date</label>
                                        <input
                                            type="date"
                                            className="form-control date-control"
                                            value={endDate}
                                            onChange={e => setEndDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <DataTable
                                        className={"audit-trail-table"}
                                        columns={columns}
                                        data={mgmtFeeData}
                                        striped="true"
                                        highlightOnHover="true"
                                        progressPending={tableLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Template>;
}
export default AuditTrail;
