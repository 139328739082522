import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ApiClient from '../api-client';
import Template from './template';
import '../css/management-fee.css';
import CurrencyDefaults from "../currency-defaults";
import {toast} from "react-toastify";
import ManagementFeeModal from '../components/management-fee-modal';

function ManagementFees(props) {
    const [mgmtFeeData, setMgmtFeeData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [mgmtFeeModalModel, setMgmtFeeModalModel] = useState({visible: false});
    const [deleteConfirmation, setDeleteConfirmation] = useState({
        isOpen: false,
        fee: null
    });

    const columns = [
        {
            name: 'Company',
            selector: row => row.company ? row.company.name : 'Any',
            sortable: true
        },
        {
            name: 'Percentage',
            selector: row => CurrencyDefaults.formatPercentage(row.percentage),
            sortable: true
        },
        {
            name: 'Minimum',
            selector: row => CurrencyDefaults.format(row.minimum)
        },
        {
            name: '',
            cell: (row) => {
                return <span
                    onClick={() => {
                        setMgmtFeeModalModel({
                            visible: true,
                            data: row,
                            percentageText: CurrencyDefaults.trim(CurrencyDefaults.factory(row.percentage).multiply(100).toString()),
                            minimumText: CurrencyDefaults.trim(row.minimum.toString())
                        });
                    }}
                    className="clickable">Edit</span>
            }
        },
        {
            name: '',
            cell: (row) => {
                return <span 
                    onClick={() => setDeleteConfirmation({ isOpen: true, fee: row })} 
                    className="clickable"
                >
                    Delete
                </span>
            }
        }
    ];

    useEffect(() => {
        async function fetchData() {
            try {
                const [companies, fees] = await Promise.all([
                    new ApiClient().fetch(`/Campaign/companies`, 'GET', null, false),
                    new ApiClient().fetch(`/ManagementFee`, 'GET', null, false)
                ]);
                setCompanies(companies);
                setMgmtFeeData(fees);
                setTableLoading(false);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    }, []);

    async function addOrUpdateFee(model) {
        let validationText = getFeeValidation(model);
        if (validationText.length > 0) {
            setMgmtFeeModalModel({...mgmtFeeModalModel, errorMessage: validationText });
            return;
        }

        model.percentage = CurrencyDefaults.trim(CurrencyDefaults.factory(mgmtFeeModalModel.percentageText).divide(100).toString());
        model.minimum = parseFloat(mgmtFeeModalModel.minimumText.replace(/,/g, ''));

        try {
            await new ApiClient().fetch('/ManagementFee', 'POST', model, {loadingMessage: 'Saving', successMessage: 'Saved'});
            // Fetch fresh data from the API
            const fees = await new ApiClient().fetch('/ManagementFee', 'GET', null, false);
            setMgmtFeeData(fees);
            setMgmtFeeModalModel({...mgmtFeeModalModel, visible: false });
        } catch (error) {
            // Api client handles messaging
        }
    }

    async function deleteFee(fee) {
        try {
            await new ApiClient().fetch(`/ManagementFee/${fee.id}`, 'DELETE', null, {loadingMessage: 'Deleting', successMessage: 'Deleted'});
            // Fetch fresh data from the API
            const fees = await new ApiClient().fetch('/ManagementFee', 'GET', null, false);
            setMgmtFeeData(fees);
            setDeleteConfirmation({ isOpen: false, fee: null });
        } catch (error) {
            // Api client handles messaging
        }
    }

    function getFeeValidation(feeModel) {
        if (!mgmtFeeModalModel.percentageText || mgmtFeeModalModel.percentageText.length === 0) {
            return 'Percentage is required';
        }
        if (isNaN(mgmtFeeModalModel.percentageText)) {
            return 'Percentage must be numeric';
        }
        if (!mgmtFeeModalModel.minimumText || mgmtFeeModalModel.minimumText.length === 0) {
            return 'Minimum is required';
        }
        
        // Clean the minimum text of commas before checking if it's numeric
        const cleanedMinimum = mgmtFeeModalModel.minimumText.replace(/,/g, '');
        if (isNaN(cleanedMinimum)) {
            return 'Minimum must be numeric';
        }

        let feeMatch = mgmtFeeData.find(x =>
            (x.company ? x.company.id : 0) === (feeModel.company ? feeModel.company.id : 0) &&
            feeModel.id !== x.id
        );
        if (feeMatch) {
            return `Management fee for ${feeMatch.company ? feeMatch.company.name : 'Any'} already exists`;
        }
        let parsedPercentage = parseFloat(mgmtFeeModalModel.percentageText);
        if (parsedPercentage > 100 || parsedPercentage < 0) {
            return 'Percentage must be between 0 and 100';
        }
        return '';
    }

    function showAddNewFee() {
        setMgmtFeeModalModel({
            visible: true,
            data: {},
            percentageText: '',
            minimumText: ''
        });
    }

    return <Template selectedNavItem="media-expenses">
        <div className="container-fluid management-fee-screen">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h4 className="m-0 font-weight-bold">Management Fees</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12">
                                    <button onClick={showAddNewFee} className="btn btn-primary add-new-fee btn-header">Add New Fee</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <DataTable
                                        columns={columns}
                                        data={mgmtFeeData}
                                        striped="true"
                                        highlightOnHover="true"
                                        dense="true"
                                        progressPending={tableLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ManagementFeeModal
            model={mgmtFeeModalModel}
            setModel={setMgmtFeeModalModel}
            companies={companies}
            onSave={addOrUpdateFee}
        />
        {deleteConfirmation.isOpen && (
            <div className="transfer-modal">
                <div>
                    <div className="modal fade show" tabIndex="-1" aria-labelledby="deleteModalLabel"
                         style={{display: 'block'}} aria-modal="true" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteModalLabel">
                                        Confirm Delete
                                    </h5>
                                    <div style={{cursor: 'default'}}>
                                        <button
                                            onClick={() => setDeleteConfirmation({ isOpen: false, fee: null })}
                                            className="close" type="button" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete the management fee for {deleteConfirmation.fee?.company ? deleteConfirmation.fee.company.name : 'Any'}?</p>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button 
                                        onClick={() => setDeleteConfirmation({ isOpen: false, fee: null })}
                                        className="btn btn-outline-orange"
                                        data-dismiss="modal">
                                        Cancel
                                    </button>
                                    <button 
                                        className="btn btn-danger"
                                        onClick={() => deleteFee(deleteConfirmation.fee)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            </div>
        )}
    </Template>;
}

export default ManagementFees;
